import { Component, OnInit, Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(public router: Router) { }

  active: String;

  ngOnInit(): void {
    if (localStorage.getItem('current-tab'))
    this.active = localStorage.getItem('current-tab');
    else
    this.active = "1";
  }

  onClick() {
    localStorage.setItem('current-tab', `${this.active}`)
  }

  ngOnDestroy() {
    localStorage.removeItem('current-tab');
  }

  redirect() {
    this.active = "3";
    this.router.navigate(['/contact'])
  }

}
