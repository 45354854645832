<div class="container">
    <div class="row" id="contact-form-container">
        <div class="col-md-6">
            <div class="contact-label">
                <p>Contact Form</p>
            </div>
            <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label>Name:</label>
                    <input type="name" class="form-control background-off-white" id="name" formControlName="name">
                </div>
                <div class="form-group">
                    <label>Number:</label>
                    <input type="number" class="form-control background-off-white" id="number" formControlName="number">
                </div>
                <div class="form-group">
                    <label>Email Address:</label>
                    <input type="email" class="form-control background-off-white" id="email" formControlName="email">
                </div>
                <div class="form-group">
                    <label>Message:</label>
                    <textarea id="msg" type="text" class="form-text background-off-white" formControlName="message"></textarea>
                </div>
                <button type="submit" class="btn btn-secondary" id="send" [disabled]="!contactForm.valid">Send</button>
            </form>
        </div>
        <div class="col-md-6">
            <div class="contact-label">
                <p>Stay in Touch</p>
            </div>
            <!--Google map-->
            <div id="map-container-google-1" class="z-depth-1-half map-container" style="height: 500px">
                <iframe src="https://maps.google.com/maps?q=salemct&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    frameborder="0" style="border:0" allowfullscreen></iframe>
            </div>
            <!--Google Maps-->
        </div>
    </div>
</div>