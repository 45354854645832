import { Component, OnInit, ViewEncapsulation, ViewChild, Output} from '@angular/core';
import { NavbarComponent } from '../navbar/navbar.component';
import { EventEmitter } from 'protractor';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css'],
})
export class ServicesComponent implements OnInit {

  constructor(private nav: NavbarComponent) {
   }

  ngOnInit() {
  }

  updateTab() {
    this.nav.redirect();
  }

  images = [
  "../assets/service-images/tile000.png",
  "../assets/service-images/tile001.png",
  "../assets/service-images/tile002.png",
  "../assets/service-images/tile003.png",
  "../assets/service-images/tile004.png",
  "../assets/service-images/tile005.png"];

}
