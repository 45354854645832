<div id="page-color">
  
<div class="container">
    <div class="row spacing-top">
        <div class="col-sm-4">
            <img [src]="images[0]" alt="">
            <div class="overlay">
                <p class="text">Our certified and trained staff provide commercial services even to those hard to clean
                    places, such as medical facilities and even operating rooms!</p>
                <button (click)="updateTab()" class="btn btn-secondary">Get Quote</button>
            </div>
        </div>
        <div class="col-sm-4">
            <img [src]="images[1]" alt="">
            <div class="overlay">
                <p class="text">Got a big building project you're finishing? Contact us for all the cleaning that comes
                    after, so your building can look sparkling clean!</p>
                <button (click)="updateTab()" class="btn btn-secondary">Get Quote</button>

            </div>
        </div>
        <div class="col-sm-4">
            <img [src]="images[2]" alt="">
            <div class="overlay">
                <p class="text">Our unique window cleaning formula is the best in the business. It removes anything,
                    dries fast, and leaves no streaks!</p>
                <button (click)="updateTab()" class="btn btn-secondary">Get Quote</button>
            </div>
        </div>
    </div>
    <div class="row  spacing-top">
        <div class="col-sm-4">
            <img [src]="images[3]" alt="">
            <div class="overlay">
                <p class="text">Our special carpet cleaning technique uses no steam and ensures a deeper clean that
                    dries in only 1 hour to prevent mold!</p>
                <button (click)="updateTab()" class="btn btn-secondary">Get Quote</button>
            </div>
        </div>
        <div class="col-sm-4">
            <img [src]="images[4]" alt="">
            <div class="overlay">
                <p class="text">Got something that just won't come off? Power wash any exterior areas around your home
                    to remove all that built up debris and mold!</p>
                <button (click)="updateTab()" class="btn btn-secondary">Get Quote</button>
            </div>
        </div>
        <div class="col-sm-4">
            <img [src]="images[5]" alt="">
            <div class="overlay">
                <p class="text">Repair your floors and give them a new look! We do it all, including refinishing your
                    hardwood floors and restoring rugs or carpets!</p>
                <button (click)="updateTab()" class="btn btn-secondary">Get Quote</button>
            </div>
        </div>
    </div>
</div>

</div>