import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Email } from './email';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  constructor(private http: HttpClient) { }

  // httpOptions = {
  //   headers: {
  //     'Origin': 'http://nulookcleaningservice.com'
  //   }
  // };

  sendMessage(msg: Email)  : Observable<any> {
    return this.http.post('https://waqfbi0yr3.execute-api.us-east-1.amazonaws.com/contact-us/contact-us', JSON.stringify(msg));
  }
}
