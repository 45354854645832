<div class="border">
    <p class="call-num">
        <span id="call">Call Today </span>
        (860) 859-3624
    </p>
</div>

<div id="header">
    <div>
        <img src="/assets/images/favicon.png" class="no-space" id="logo">
        <a id="title">Nulook Cleaning Service</a>
    </div>
    <nav>
        <ul ngbNav [(activeId)]="active" class="nav-tabs menu" (click)="onClick()">
            <li [ngbNavItem]="1">
                <a ngbNavLink routerLink="/home" routerLinkActive="active">Home</a>
            </li>
            <!-- <li [ngbNavItem]="2">
                <a ngbNavLink routerLink="/about" routerLinkActive="active">About Us</a>
            </li> -->
            <li [ngbNavItem]="2" routerLink="/services" routerLinkActive="active">
                <a ngbNavLink>Services</a>
            </li>
            <li [ngbNavItem]="3" routerLink="/contact" routerLinkActive="active">
                <a ngbNavLink>Contact</a>
            </li>
        </ul>
    </nav>
</div>