import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {Subject, Observable} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import { Email } from '../email';
import { ConnectionService } from '../connection.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {

  constructor(private conn: ConnectionService) { }

  images = ["../assets/images/certs.png",
            "../assets/images/commercial_residential.jpg",
            "../assets/images/why.png",
            "../assets/images/how.png",
            "../assets/images/bullet-vector-art.png",
            "../assets/images/state_federal.png"];

  private _success = new Subject<string>();

  staticAlertClosed = false;
  successMessage = '';
  onSuccess: boolean = false;
  email: Email = {};

  ngOnInit(): void {
    setTimeout(() => this.staticAlertClosed = true, 20000);

    this._success.subscribe(message => this.successMessage = message);
    this._success.pipe(
      debounceTime(2500)
    ).subscribe(() => this.successMessage = '');
  }

  emailForm = new FormGroup({
    name: new FormControl('', Validators.required),
    number: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    message: new FormControl('', Validators.required),
  });


  onSubmit() {
    this.email.name = this.emailForm.get('name').value;
    this.email.number = this.emailForm.get('number').value;
    this.email.email = this.emailForm.get('email').value;
    this.email.message = this.emailForm.get('message').value;

    this.conn.sendMessage(this.email).subscribe((resp)=>{
      console.log(`Response: ${resp}`);
      this._success.next(`Message Sent!`);
    }, (err)=>{
      console.log(`Error response: ${err}`);
      alert('Something went wrong! If this problem continues, please contact us by phone number instead');
    });;

    
      this.emailForm.reset();
  }
  

}
