<div class="container">
    <div class="row">
        <div class="col-lg-6 no-space image-fit">

            <ngb-carousel *ngIf="images" [showNavigationIndicators]="false" [interval]="4000" [pauseOnHover]="true">
                <ng-template ngbSlide>
                    <div class="picsum-imgwrapper">
                        <img [src]="images[0]" class="image image-fit">
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="picsum-imgwrapper">
                        <img [src]="images[1]" class="image image-fit">
                    </div>
                    <div class="carousel-caption opaque-box">
                        <div>
                            <h5>Commercial & Residential</h5>
                        </div>
                    </div>
                </ng-template>
            </ngb-carousel>
        </div>
        <div class="col-md-6 emailForm">
            <div>
                <h3 id="contact-us">Contact us for a Free Quote!</h3>
                <p class="center-text">Please complete this form and we will get back to you ASAP</p>
            </div>
            <form [formGroup]="emailForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <input class="form-control" formControlName="name" placeholder="Name">
                </div>
                <div class="form-group">
                    <input class="form-control" formControlName="number" placeholder="Number">
                </div>
                <div class="form-group">
                    <input type="email" class="form-control" formControlName="email" placeholder="Email">
                </div>
                <div class="form-group">
                    <textarea id="msg" type="text" class="form-text" placeholder="  Write Your Message"
                        formControlName="message"></textarea>
                </div>
                <button type="submit" class="btn btn-secondary" [disabled]="!emailForm.valid">Send</button>
            </form>
            <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = ''">{{successMessage}}
            </ngb-alert>
        </div>
    </div>
    <div class="row background-green">
        <div class="col-md-6 center-text text-column border-line">
            <h1>Why We Do it</h1>
            <div class="text-box font-size-20">
                <p>We believe in becoming a strong example of success in small business. We exist because of a single
                    idea
                    that has since grown into over 25 years of service. Our purpose is to provide quality service at
                    affordable rates</p>
            </div>
        </div>
        <div class="col-md-6 text-column border-line">
            <h1 class="center-text">How We Do it</h1>
            <div class="row">
                <div class="col-md-6">
                    <h4 class="center-text snell-font">Better Carpets</h4>
                    <div class="indent-text futura-font font-size-20">
                        <p><img [src]="images[4]" class="bullet-img">No Steam</p>
                        <p><img [src]="images[4]" class="bullet-img">Dry in 1 hour</p>
                        <p><img [src]="images[4]" class="bullet-img">No spores or mold</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <h4 class="center-text snell-font">Better Cleaning</h4>
                    <div class="indent-text futura-font font-size-20">
                        <p><img [src]="images[4]" class="bullet-img">No streaks</p>
                        <p><img [src]="images[4]" class="bullet-img">Evaporates quickly</p>
                        <p><img [src]="images[4]" class="bullet-img">No grim or grease</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 text-column background-grey text-center text-color-white">
            <div class="row">
                <div class="col-md-6">
                    <h2 class="padding-top lulo-font">Our Company</h2>
                    <div class="text-box-narrow font-size-18">
                        <p>NuLook Cleaning Service started as a small local business back in 1991, and has grown to
                            provide
                            both commercial and residential services across Connecticut for over 25 years!</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <h4 class="padding-top">Head Office</h4>
                    <div id="office-info">
                        <p class="no-space">663 Old Colchester Road</p>
                        <br>
                        <p id="address-2">Salem, CT 06420</p>
                        <p>_____</p>
                        <p>Nulookcleaningservice@yahoo.com</p>
                    </div>
                    <img [src]="images[05]" id="footer-img-fit">
                </div>
            </div>
        </div>
    </div>
</div>