import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Email } from '../email';
import { ConnectionService } from '../connection.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  constructor(private conn: ConnectionService) { }

  ngOnInit() {
  }

  email: Email = {};

  contactForm = new FormGroup({
    name: new FormControl('', Validators.required),
    number: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    message: new FormControl('', Validators.required),
  });

    onSubmit() {
      this.email.name = this.contactForm.get('name').value;
      this.email.number = this.contactForm.get('number').value;
      this.email.email = this.contactForm.get('email').value;
      this.email.message = this.contactForm.get('message').value;
  
      this.conn.sendMessage(this.email);

      this.contactForm.reset();
    }

}
